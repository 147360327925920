import { createContext, useEffect, useReducer } from 'react';
import { MatxLoading } from 'app/components';
import { useLocation, useNavigate } from 'react-router-dom';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectPage = queryParams.get('next');

  const login = async (email, password) => {
    console.log("Inside login", redirectPage);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email, 
        password
      }),
  }).then(response => response.json());
    const { user } = response;
    console.log("user", user);

    if(user){
      dispatch({ type: 'LOGIN', payload: { user } });
      // setTimeout(() =>{
        navigate(redirectPage || '/'); // Redirect to the specified page or homepage if redirectPage is not provided
      // },2000)
    }
  };

  const register = async (firstname, lastname, email, username, password) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/register`, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ firstname, lastname, email, username, password})
  }).then(response => response.json());
    
    const { user } = response.data;
    if(user)
      dispatch({ type: 'REGISTER', payload: { user } });
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/admin/profile`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json());
        console.log("response", response);
        if(response.user){
          dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: response.user } });
          if(!response.user.isVerified)
            navigate('/verification');
        }
        else
          dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
