import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DOMAINS': {
      return { ...state, domains: action.payload };
    }

    case 'SET_DOMAIN': {
      return { ...state, selectedDomain: action.payload };
    }

    default:
      return state;
  }
};

const DomainsContext = createContext({
  domains: [],
  selectedDomain: {},
  getDomains: () => {},
  setDomain: () => {}
});

export const DomainsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, []);
  const getDomains = async () => {
    console.log("Inside DomainsProvider getDomains");
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/domains`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
        })
      });

      if (res.ok) {
        let data = await res.json();
        let localSelectedDomains = JSON.parse(window.localStorage.getItem("selecteddomain"));
        console.log("localSelectedDomains", localSelectedDomains);
        console.log("Domain List data", data);
        dispatch({ type: 'LOAD_DOMAINS', payload: data });
        setDomain(localSelectedDomains ??  data[0]);
      } else {
        dispatch({ type: 'LOAD_DOMAINS', payload: [] });
      }

    } catch (e) {
      console.error(e);
    }
  };

  const setDomain = async (domain) => {
    try {
      // console.log("Inside setDomain", domain, state.domains, state.selectedDomain);
      dispatch({ type: 'SET_DOMAIN', payload: domain });
      window.localStorage.setItem("selecteddomain", JSON.stringify(domain));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getDomains();
  }, []);

  return (
    <DomainsContext.Provider
      value={{
        getDomains,
        setDomain,
        domains: state.domains,
        selectedDomain: state.selectedDomain
      }}
    >
      {children}
    </DomainsContext.Provider>
  );
};

export default DomainsContext;
