import { Fragment, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useDomains from 'app/hooks/useDomains';

const DomainList = ({ }) => {
    let { domains, selectedDomain, setDomain } = useDomains();
    console.log("domains, selectedDomain DomainList", domains, selectedDomain);

    return (
        <Fragment>
            {domains && domains.length && (<Autocomplete
                disableClearable
                id="size-small-standard"
                size="small"
                style={{ width: 160 }}
                limitTags={1}
                options={domains}
                getOptionLabel={(option) => option.Name}
                getOptionDisabled={(option) => option.Status !== 'Verified'}
                value={selectedDomain}
                onChange={(event, value) => {
                    setDomain(value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{ textAlign: 'center' }}
                        variant="standard"
                        placeholder="Domains"
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        {option.Name}
                    </li>
                )}
            />
            )}
        </Fragment>
    );
};

export default DomainList;
